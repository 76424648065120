import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { MappedImage } from '../Image'

export const HorizontalIconModule = ({ heading, subHeading, subModules }) => (
  <Container fluid className="horizontal-icon-outer-container">
    <Container className="horizontal-icon-inner-container">
      <Row className="module-heading-wrapper p-2 m-auto text-center">
        <h2 className="module-heading my-2">{heading}</h2>
        {subHeading && (
          <div className="module-subheading my-2">{subHeading}</div>
        )}
      </Row>
      <div className="submodule-wrapper">
        {subModules &&
          subModules.map(item => (
            <Row
              key={item.heading}
              className="horizontal-icon-item p-lg-3 p-md-2 mx-auto"
            >
              <Col
                sm={12}
                md={4}
                className="horizontal-icon-item-col d-flex flex-column justify-content-center"
              >
                <div className="d-flex h-icon-img" alt={item.image.title}>
                  <MappedImage
                    className="m-auto my-3 text-right"
                    image={item.image}
                  />
                </div>
              </Col>
              <Col className="h-icon-submodule-item-col" sm={12} md={8}>
                <h3 className="my-3 w-75 h-icon-item-title">{item.heading}</h3>
                <p className="my-3 w-75 h-icon-item-subtitle">
                  {item.subtitle}
                </p>
              </Col>
            </Row>
          ))}
      </div>
    </Container>
  </Container>
)

export const mapHorizontalIconModuleProps = props => {
  return {
    heading: props.heading,
    subHeading: props.subHeading,
    subModules: props.subModules,
  }
}
